<template>
  <div class="container mt-3 mb-2">
    <b-card>
      <surveys-form :token="token" />
    </b-card>
  </div>
</template>
<script>
import {
  BCard
} from 'bootstrap-vue'; 
import SurveysForm from '@/participants/surveys/SurveysForm.vue';
export default {
  name: 'SurveysPublicForm',
  components: {
    BCard,
    SurveysForm
  },
  computed: {
    token() {
      return this.$route.params.token;
    }
  }
};
</script>