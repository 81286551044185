<template>
  <div v-if="isLoading">
    <aom-skeleton-loader />
  </div>
  <div v-else>
    <h2 class="mt-2 mb-1">
      {{ surveyTitle }}
    </h2>
    <validation-observer
      ref="observer"
      v-slot="{ pristine, invalid, handleSubmit }"
    >
      <b-form
        ref="form"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <div
          v-for="(question, index) in displayQuestions"
          :key="question.id"
          class="mb-1 box-shadow"
        >
          <template v-if="question.type_id === modelQuestionTypes.TEXT">
            <aom-application-single-text-box
              :question.sync="question"
              :index="index"
            />
          </template>
          <template v-if="question.type_id === modelQuestionTypes.CHOICE">
            <aom-application-choice
              :question.sync="question"
              :index="index"
            />
          </template>
          <template v-if="question.type_id === modelQuestionTypes.MATCHING">
            <aom-application-multi-choice-matching
              :question.sync="question"
              :index="index"
            />
          </template>
          <template v-if="question.type_id === modelQuestionTypes.SCALE">
            <aom-application-rating
              ref="rating-scale"
              :question.sync="question"
              :index="index"
            />
          </template>
        </div>
        <b-row class="mt-3 d-flex align-items-end justify-content-end">
          <b-col
            sm="4"
            md="4"
            class="mb-1"
          >
            <b-button
              block
              type="reset"
              variant="outline-dark"
              @click="clearForm"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col
            sm="4"
            md="4"
            class="mb-1"
          >
            <b-button
              block
              type="submit"
              variant="primary"
            >
              <b-spinner
                v-if="isUpdating"
                small
              />
              <span v-if="isUpdating"> Updating...</span>
              <span v-else>Submit</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { BRow, BCol, BSpinner, BForm, BButton} from "bootstrap-vue";
import { mapGetters } from "vuex";
import { questionTypes as modelQuestionTypes } from "@/models/questionTypes";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { ValidationObserver } from "vee-validate";
import AomApplicationSingleTextBox from "@aom-core/AomApplicationSingleTextBox.vue";
import AomApplicationChoice from "@aom-core/AomApplicationChoice.vue";
import AomApplicationRating from "@aom-core/AomApplicationRating.vue";

export default {
  name: "SurveysForm",
  components: {
    AomSkeletonLoader,
    ValidationObserver,
    AomApplicationChoice,
    AomApplicationSingleTextBox,
    AomApplicationRating,
    BRow,
    BCol,
    BSpinner,
    BForm,
    BButton
  },
  props: {
    token: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      modelQuestionTypes: modelQuestionTypes,
      routeName: null,
      isLoading: false,
      isUpdating: false,
    };
  },

  computed: {
    ...mapGetters("app", {
      appCurrentProgram: "currentProgram",
      isParticipantPortal: "isParticipantPortal",
    }),
    ...mapGetters("profile", ["profile", "isLoggedIn"]),
    ...mapGetters("participants", ["surveyAnswerData"]),

    displayQuestions() {
      if (this.surveyAnswerData && this.surveyAnswerData.questions) {
        return this.surveyAnswerData.questions.filter(
          q => q.type_id !== modelQuestionTypes.SCALE || !q.parent_id
        );
      }
      return [];
    },

    surveyTitle() {
      if (this.surveyAnswerData) {
        return this.surveyAnswerData.translations[0].title;
      }
      return "";
    },
  },

  async created() {
    try {
      this.isLoading = true;
      if (this.token) {
        await this.$store.dispatch(
          "participants/FETCH_OWN_SURVEY_ANSWERS_BY_TOKEN",
          {
            token: this.token,
            formId: this.$route.params.form,
            matchId: this.$route.params.match,
          }
        );
      } else {
        if (
          this.isParticipantPortal &&
          this.appCurrentProgram &&
          this.appCurrentProgram.id
        ) {
          await this.$store.dispatch("participants/FETCH_OWN_SURVEY_ANSWERS", {
            programId: this.appCurrentProgram.id,
            formId: this.$route.params.form,
            userId: this.profile.id,
            matchId: this.$route.params.match,
          });
        }
      }
    } catch (e) {
      const { data } = e.response;
      if (this.token) {
        this.$bvModal
          .msgBoxOk(
            data?.message ? data.message : "Token is invalid or has expired.",
            {
              title: "Error",
            }
          )
          .then(() => {
            this.$router.push({
              name: "participant-login",
              params: {
                clientSlug: this.$route.params.clientPath,
                programPath: this.$route.params.programPath,
              },
            });
          });
      } else {
        this.$toast(makeErrorToast("Survey not loaded."));
        this.$log.error(e);
      }
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    clearForm() {
      this.$router.push({
        name: "participant-surveys",
      });
    },
    makeRequestParams() {
      const params = {};
      if (this.appCurrentProgram && this.appCurrentProgram.id) {
        params.programId = this.appCurrentProgram.id;
      }
      params.formId = this.$route.params.form;
      params.userId = this.profile.id;
      params.token = this.$route.params.token;
      params.matchId = this.$route.params.match;

      return params;
    },

    makeRequestData() {
      const formAns = this.displayQuestions
        .map(q => {
          if (q.answers.length > 0 && q.type_id !== modelQuestionTypes.SCALE) {
            return q.answers;
          } else {
            return q.statements.map(s => s.answers).flat();
          }
        })
        .flat();
      const data = { answers: formAns };
      return data;
    },
    async onSubmit() {
      try {
        this.isUpdating = true;
        const params = this.makeRequestParams();
        const data = this.makeRequestData();
        const isSuccess = this.token
          ? await this.$store.dispatch(
              "participants/SAVE_OWN_SURVEY_ANSWERS_BY_TOKEN",
              {
                params,
                data,
              }
            )
          : await this.$store.dispatch("participants/SAVE_OWN_SURVEY_ANSWERS", {
              params,
              data,
            });

        if (isSuccess) {
          this.$bvModal
          .msgBoxOk(
            "Thank you for completing the survey.",
            {
              title: "Survey",
            }
          )
          .then(() => {
            if(!this.isLoggedIn) {
              return;
            }
            this.$router.push({
              name: "participant-home",
            });
          });
        }
      } catch (e) {
        const { data } = e.response;
        this.$toast(makeErrorToast(data?.message));
        this.$log.error(e);
      } finally {
        this.isUpdating = false;
      }
    },
  },
};
</script>
